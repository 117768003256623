/* eslint-disable no-var */
/* eslint-disable vars-on-top */
const bowser = require('bowser');
const PrebidRequester = require('PrebidRequesterImplementation');
const { getSspId } = require('relevant-shared/hbAnalytics/utils');
const Utils = require('./utils');
const {
	AS_FLAGS, SYSTEM_PARAM_PREFIX, GAM_DUPLICATE_PATH_SEPARATOR, EVENTS,
	MAX_HBA_GET_PARAM_CHARS,
} = require('./sharedConstants');

const hbmAuctionOf = ({ auctionId }) => (
	PrebidRequester.instance ? PrebidRequester.instance.auctionById(auctionId) : null
);

let isInCookieDisableGroup;
navigator.cookieDeprecationLabel?.getValue().then((label) => {
	isInCookieDisableGroup = ['treatment_1.1', 'treatment_1.2', 'treatment_1.3', 'control_2'].indexOf(label) >= 0;
});

window.relevantDigital = window.relevantDigital || {};
window.relevantDigital.DEFAULT_PROGRAMMATIC_TAG_JS = function (FIELDS, SITE) {
	const BidderHandlers = {
		defaultHandler: {
			getSspId(params) {
				return getSspId(params);
			},
		},
	};

	let logNr = 0;
	const mainSendDelay = 0;
	const extraSendDelay = 2000;
	let analyticsURL;
	const useLogging = ~location.toString().indexOf('analyticsDebug') || ~document.cookie.indexOf('analyticsDebug');

	function log(str) {
		if (!useLogging) {
			return;
		}
		const { fmt } = Utils;
		const now = new Date();
		const dateStr = `${fmt(now.getHours(), 2)}:${fmt(now.getMinutes(), 2)}:${fmt(now.getSeconds(), 2)}.${fmt(now.getMilliseconds(), 3)}`;
		const msg = `[${fmt(logNr += 1, 4)}][${dateStr}] ${str}`;
		console.info(msg);
	}

	const Bid = Utils.assign(function (auction, bidSettings) {
		Utils.assign(this, bidSettings, {
			bidIdx: -1,
			auction,
			timedOut: false,
			noBid: false,
			bidResponse: null,
			bidWon: false,
			renderFailed: false,
			renderSuccess: false,
			unloadBeforeResponse: false,
			isVideo: false,
			isNative: false,
			adserverWon: false,
			responseMs: 0,
			renderMs: 0,
			bidderHandler: BidderHandlers[bidSettings.bidder] || BidderHandlers.defaultHandler,
			hbmData: Utils.find(auction.hbmAuction?.usedUnitDatas || [], (d) => d.code === bidSettings.adUnitCode),
		});
		this.bidder = Auction.bidderAliasMap[this.bidder] || this.bidder;
		this.sspId = this.getSspId();
	}, {
		prototype: {
			isDoneOrAds() {
				return this.isAdserverBid || this.timedOut || this.noBid || this.bidResponse;
			},

			getFlags() {
				let flags = 0;
				const that = this;
				AS_FLAGS.forEach((flg, idx) => {
					if (that[flg]) {
						flags += 2 ** idx;
					}
				});
				return flags;
			},

			getSspId() {
				if (this.__rlvId) {
					return this.__rlvId;
				}
				return `${this.bidder}-${this.bidderHandler.getSspId(this.params || {})}`;
			},

			getConvertedCpm() {
				const { currency } = this.bidResponse || {};
				let { cpm = 0 } = this.bidResponse || {};
				if (typeof cpm === 'string') {
					cpm = parseFloat(cpm) || 0;
				}
				const orgPrice = PrebidRequester.instance?.getRlvResponseInfo(this.bidResponse)?.org_price;
				if (orgPrice !== undefined) {
					cpm = orgPrice; // Actual cpm (cpm manipulated for Xandr Guaranteed Line Items)
				}
				// eslint-disable-next-line no-use-before-define
				return Auction.getConvertedCpm(cpm, currency);
			},

			finalAdUnitCode() {
				return this.adjustedAdUnitCode || this.adUnitCode;
			},

			toServerObject() {
				const floor = this.hbmData?.adUnit.getHbaFloor();
				const obj = {
					bidIdx: this.bidIdx,
					code: `${this.auction.getSiteId()}-${this.finalAdUnitCode()}`,
					bidder: this.bidder,
					sspId: this.sspId,
					flags: this.getFlags(),
					responseMs: this.responseMs,
					// only log floor-prices when using HBM to not break compression
					floor: typeof floor === 'number' ? floor : undefined,
					lineItemId: this.lineItemId,
					path: this.path,
				};
				const br = this.bidResponse;
				if (br) {
					Utils.assign(obj, {
						id: this.id, // no need for it when no-bid, as there won't be any more updates..
						width: br.width,
						height: br.height,
						cpm: this.getConvertedCpm(),
						...(br.amzCode && { amzCode: br.amzCode }),
						...(br.amzMult && { amzMult: br.amzMult }),
					});
					if (this.bidWon) {
						Utils.assign(obj, {
							renderMs: this.renderMs,
						});
					}
				}
				return obj;
			},
		},
	});

	var Auction = Utils.assign(function (auctionSettings) {
		Utils.assign(this, auctionSettings, {
			bidsById: {},
			id: Utils.uniqId(),
			mainDataSent: false,
			mainSendPending: false,
			extraSendPending: false,
			serverBids: null,
			pendingCommands: [],
			runningCommand: null,
			timestamp: auctionSettings.timestamp || new Date(),
		});
		this.hbmAuction = hbmAuctionOf(this);
		const allBidSettings = [].concat(
			...(auctionSettings.bidderRequests || []).map((req) => req.bids),
			...(this.hbmAuction ? this.hbmAuction.getNonPbjsBidsForHba() : []),
		);
		allBidSettings.forEach((bidSettings) => {
			this.bidsById[bidSettings.bidId] = new Bid(this, bidSettings);
		});
	}, {
		auctions: [],
		bidderAliasMap: {},
		globalSettings: null,

		onBeforeHbmAuctionSetup() {},

		init(pbjs, globalSettings, globalOverrideSettings) {
			Auction.pbjs = pbjs;
			Auction.globalSettings = Utils.assign(
				{},
				globalSettings,
				window.RELEVANT_ANALYTICS_SETTINGS,
				globalOverrideSettings,
			);

			function handleEvent(ev, args, ts) {
				log(`${ev} - ${args}`);
				if (Auction[ev]) {
					Auction[ev](args, ts);
				}
			}

			const start = window.performance?.timeOrigin;
			const events = pbjs.getEvents?.() || window.relevantDigital.pbEventLog || [];
			events.forEach((event) => {
				const ev = event.eventType || event.ev;
				let { ts } = event;
				if (!ts) {
					ts = start && event.elapsedTime ? new Date(start + event.elapsedTime) : new Date();
				}
				handleEvent(ev, event.args, ts);
			});

			EVENTS.forEach((ev) => {
				pbjs.onEvent(ev, (args) => {
					handleEvent(ev, args, new Date());
				});
			});
			addEventListener('beforeunload', () => {
				Auction.auctions.forEach((auction) => {
					auction.allBids().forEach((bid) => {
						if (!bid.isDoneOrAds()) {
							bid.unloadBeforeResponse = true;
						}
					});
					if (!auction.mainDataSent) {
						auction.sendMainDataInternal();
					} else {
						auction.sendUpdatesInternal();
					}
				});
			});
		},

		find(auctionId) {
			return Utils.find(Auction.auctions, (auction) => auction.auctionId === auctionId);
		},

		findBid(bidSettings) {
			const auction = Auction.find(bidSettings.auctionId);
			return auction && (auction.bidsById[bidSettings.bidId || bidSettings.requestId]);
		},

		findBidBy(cb) {
			for (const auction of Auction.auctions) {
				const match = Utils.find(Utils.values(auction.bidsById), cb);
				if (match) {
					return match;
				}
			}
			return null;
		},

		getConvertedCpm(cpm, currency) {
			const { convertToCurrency } = FIELDS;
			if (!convertToCurrency || !cpm || !currency || convertToCurrency === currency) {
				return cpm;
			}
			const { exchangeRates = {} } = SITE;
			if (!exchangeRates[currency] || !exchangeRates[convertToCurrency]) {
				return cpm;
			}
			return cpm * (exchangeRates[convertToCurrency] / exchangeRates[currency]);
		},

		getImpressionUrl(bidSettings) {
			const bid = Auction.findBid(bidSettings);
			if (!bid) {
				return null;
			}
			const cmd = {
				cmd: 'updates',
				systemId: SITE.systemId,
				id: bid.auction.id,
				diffs: [{
					bidIdx: bid.bidIdx,
					flags: 2 ** AS_FLAGS.indexOf('renderSuccess'),
					renderMs: bid.renderMs,
				}],
			};
			return `${analyticsURL}/analytics?param=${JSON.stringify(cmd)}`;
		},

		auctionInit(auctionSettings) {
			const auction = new Auction(auctionSettings);
			Auction.auctions.push(auction);
			if (auction.hbmAuction) {
				Auction.hbmEnabled = true; // "Legacy" value used by some custom parameters
				auction.hbmAuction.onHbaAuctionCreated(auction);
			}
		},

		bidResponse(bidSettings, ts) {
			Auction.update(bidSettings, (bid) => {
				bid.bidResponse = bidSettings;
				bid.responseMs = bid.auction.msSinceStart(ts);
				bid.ssMs = bidSettings.serverResponseTimeMs;
				bid.isVideo = bidSettings.mediaType === 'video';
				bid.isNative = bidSettings.mediaType === 'native';
				bid.isDeal = !!bidSettings.dealId;
			});
		},

		update(bidSettings, fn) {
			const bid = Auction.findBid(bidSettings);
			if (!bid) {
				throw Error(`No bid from settings: ${JSON.stringify(bidSettings)}`);
			}
			const { auction } = bid;

			fn(bid);
			if (auction.mainDataSent) {
				auction.sendUpdates();
			} else if (auction.isHbDone()) {
				auction.sendMainData();
			}
		},

		bidTimeout(bids) {
			bids.forEach((bidSettings) => {
				Auction.update(bidSettings, (bid) => {
					bid.timedOut = true;
				});
			});
		},

		noBid(bidSettings, ts) {
			Auction.update(bidSettings, (bid) => {
				bid.noBid = true;
				bid.responseMs = bid.auction.msSinceStart(ts);
				bid.ssMs = bidSettings.serverResponseTimeMs;
			});
		},

		bidWon(bidSettings, ts) {
			Auction.update(bidSettings, (bid) => {
				bid.bidWon = true;
				bid.renderMs = bid.auction.msSinceStart(ts);
				if (bid.hbmData) {
					bid.hbmData.hbWon = true;
				}
			});
			const isOutstream = bidSettings.mediaType === 'video' && bidSettings.renderer;
			if (!(hbmAuctionOf(bidSettings) && isOutstream)) {
				setTimeout(() => {
					Auction.update(bidSettings, (bid) => {
						if (!bid.renderFailed) {
							bid.renderSuccess = true;
						}
					});
				});
			}
		},

		adRenderFailed(bidSettings) {
			Auction.update(bidSettings, (bid) => {
				bid.renderFailed = true;
			});
		},

		markAdserverWon(bidSettings) {
			let updated = false;
			Auction.update(bidSettings, (bid) => {
				if (!bid.adserverWon) {
					bid.adserverWon = true;
					updated = true;
				}
			});
			return updated;
		},

		registerImpression(bidSettings) {
			Auction.update(bidSettings, (bid) => {
				bid.bidWon = true;
				bid.renderSuccess = true;
			});
		},

		registerImpressionByAdId(id) {
			// we might get the same adId multiple times - register first one that hasn't been registered yet
			const matchesId = (str) => str && `${str}`.indexOf(id) >= 0;
			const bidSettings = Auction.findBidBy((bid) => {
				if (bid.bidResponse && !bid.renderSuccess && id) {
					const { adId, creativeId, vastXml } = bid.bidResponse;
					if (matchesId(adId) || matchesId(creativeId)) {
						return true;
					}
					if (vastXml?.includes?.(id)) {
						try { // This is probably the right bid, let's parse the VAST to make sure
							const xml = new DOMParser().parseFromString(bid.bidResponse.vastXml, 'application/xml');
							if (Utils.find([...xml.getElementsByTagName('Ad')], (ad) => matchesId(ad.id))) {
								return true;
							}
						} catch (e) { /** ignore */ }
					}
					return false;
				}
				return false;
			});
			if (bidSettings) {
				Auction.registerImpression(bidSettings);
			}
			return !!bidSettings;
		},

		prototype: {
			allBids() {
				return Utils
					.values(this.bidsById)
					.sort((a, b) => a.bidIdx - b.bidIdx);
			},

			msSinceStart(ts) {
				return (ts || new Date()) - this.timestamp;
			},

			isHbDone() {
				for (const id in this.bidsById) {
					if (!this.bidsById[id].isDoneOrAds()) {
						return false;
					}
				}
				return true;
			},

			onHbmAdserverRequestSent({ isTimeout }) {
				const notDone = this.allBids().filter((bid) => !bid.isDoneOrAds());
				if (isTimeout) {
					Auction.bidTimeout(notDone);
				} else if (notDone.length) { // Quick-fix for richaudience invalid-bid bug
					const events = Auction.pbjs?.getEvents?.() || [];
					const endTsPerBidder = {};
					var start = (window.performance || {}).timeOrigin;
					events.forEach((e) => {
						const { bidderCode: bidder } = e?.args || {};
						if (e && e.eventType === 'bidderDone' && e.elapsedTime && start && bidder) {
							endTsPerBidder[bidder] = new Date(start + e.elapsedTime);
						}
					});
					notDone.forEach((bid) => Auction.noBid(bid, endTsPerBidder[bid.bidder]));
				}
			},

			sendUpdatesInternal() {
				const newServerBids = this.serverBidArray();
				const diffs = [];
				const that = this;
				newServerBids.forEach((newBid, idx) => {
					let diffObj;
					const oldBid = that.serverBids[idx];
					for (const key in newBid) {
						if (newBid[key] !== oldBid[key]) {
							diffObj = diffObj || { bidIdx: newBid.bidIdx };
							diffObj[key] = newBid[key];
						}
					}
					if (diffObj) {
						['path', 'lineItemId'].forEach((key) => { // Always send these
							if (newBid[key]) {
								diffObj[key] = newBid[key];
							}
						});
						diffs.push(diffObj);
					}
				});
				this.extraSendPending = false;
				if (!diffs.length) {
					log('Empty update');
					return;
				}
				this.serverBids = newServerBids;
				const obj = {
					cmd: 'updates',
					systemId: SITE.systemId,
					id: that.id,
					diffs,
				};
				this.sendCommand(obj);
				log(`UPDATE Analytics request triggered, bid diffs: ${obj.diffs.length}`);
			},

			sendUpdates() {
				if (this.extraSendPending) {
					return;
				}
				this.extraSendPending = true;
				setTimeout(this.sendUpdatesInternal.bind(this), extraSendDelay);
			},

			getSystemParams() {
				const capitalize = (string) => string.replace(/(?:^|\s)\S/g, (char) => char.toUpperCase());
				const { browser, os, platform } = window.relevantDigital.platformData || {};
				const unknown = '[Unknown]';
				const loc = Utils.getLocation();
				return {
					...(loc && {
						Hostname: loc.hostname,
						URL: loc.origin + loc.pathname,
					}),
					...(isInCookieDisableGroup && { 'Chrome Cookie Disabled Group': 'Yes' }),
					'Prebid.js version': Auction.pbjs.version,
					'Screen width': screen.width,
					'Screen height': screen.height,
					'Browser name': browser && browser.name ? capitalize(browser.name) : unknown,
					'Browser version': browser && browser.name && browser.version
						? capitalize(`${browser.name} ${browser.version.split('.')[0]}`) : unknown,
					'Operating system': os && os.name ? capitalize(os.name) : unknown,
					Platform: platform && platform.type ? capitalize(platform.type) : unknown,
					...this.hbmAuction?.getHbaSystemParams(),
				};
			},

			getCustomParams() {
				const custom = Auction.globalSettings.getCustomParams && Auction.globalSettings.getCustomParams(this);
				const res = {};
				const systemParams = this.getSystemParams();
				if (systemParams.Platform === 'Bot') {
					this.blockCalls = true;
				}
				for (const k in systemParams) {
					res[SYSTEM_PARAM_PREFIX + k] = systemParams[k];
				}
				return Utils.assign(res, custom);
			},

			reAdjustDuplicationPaths() {
				const allMappings = {};
				this.allBids().forEach((bid) => {
					const code = bid.finalAdUnitCode();
					const [startStr, duplStr] = (code || '').split(GAM_DUPLICATE_PATH_SEPARATOR);
					if (duplStr) {
						allMappings[startStr] = allMappings[startStr] || { nextNr: 2, mapping: {} };
						const mapping = allMappings[startStr];
						let changeTo = mapping.mapping[duplStr];
						if (!changeTo) {
							changeTo = mapping.nextNr.toString();
							mapping.nextNr += 1;
							mapping.mapping[duplStr] = changeTo;
						}
						if (duplStr !== changeTo) {
							bid.adjustedAdUnitCode = `${startStr}${GAM_DUPLICATE_PATH_SEPARATOR}${changeTo}`;
						}
					}
				});
			},

			initAdUnitCodeMapping() {
				const hbmGamAds = Utils.find(this.hbmAuction?.adservers || [], (ads) => (
					ads.getType() === 'google' && !ads.isInstreamOnly()
				));
				if (window.googletag?.pubads && !(this.hbmAuction && !hbmGamAds)) {
					const slots = googletag.pubads().getSlots();
					const nameMap = {};
					slots.forEach((slot) => {
						let path;
						if (hbmGamAds) {
							path = hbmGamAds.rlvConvertedAdUnitPath(slot).toLowerCase();
						} else {
							const adsSettings = Utils.find(Utils.values(SITE.globalAdserverSettings), (s) => (
								s.networkCode && slot.getAdUnitPath().indexOf(s.networkCode) >= 0
							));
							path = Utils.rlvConvertedGamAdUnitPath(slot, adsSettings || {}).toLowerCase();
						}
						const lastPart = path.substr(path.lastIndexOf('/') + 1);
						nameMap[path] = path;
						nameMap[lastPart] = path;
						nameMap[slot.getSlotElementId()] = path;
					});
					this.allBids().forEach((bid) => {
						bid.adjustedAdUnitCode = nameMap[bid.adUnitCode] || nameMap[bid.adUnitCode.toLowerCase()];
						if (!bid.adjustedAdUnitCode && bid.adUnitCode.indexOf(GAM_DUPLICATE_PATH_SEPARATOR) > 0) {
							bid.adjustedAdUnitCode = bid.adUnitCode; // We want to keep this code as it is
						}
					});
				}
				if (window.apntag) {
					const tags = (window.apntag.requests || {}).tags || {};
					this.allBids().forEach((bid) => {
						const tag = tags[bid.adUnitCode] || {};
						const candidate = tag.tagId || tag.invCode;
						if (!bid.adjustedAdUnitCode && candidate) {
							bid.adjustedAdUnitCode = candidate.toString();
						}
					});
				}
				// always run code below, as googletag is no guarantee we're using ad manager
				const { adjustAdUnitCode, alwaysAdjustAdUnitCode } = Auction.globalSettings;
				const context = { auction: this };
				const adjustedToBaseMap = {}; // Don't give 2 different unit codes the same adjusted ad unit code
				this.allBids().forEach((bid) => {
					if ((!bid.adjustedAdUnitCode || alwaysAdjustAdUnitCode) && adjustAdUnitCode) {
						bid.adjustedAdUnitCode = adjustAdUnitCode(bid.adUnitCode, bid, context);
					}
					if (!bid.adjustedAdUnitCode && bid.adUnitCode.indexOf('/') < 0) { // not for GAM tags
						const sepIdx = bid.adUnitCode.indexOf(GAM_DUPLICATE_PATH_SEPARATOR);
						const baseCode = sepIdx < 0 ? bid.adUnitCode : bid.adUnitCode.slice(0, sepIdx);
						const cand = (/(\W|_)(\d+)$/.exec(baseCode) || [])[2];
						if (cand && cand.length >= 4) { // if we find a number < 1000, it's probably not a placement id
							const existingBase = adjustedToBaseMap[cand];
							if (!existingBase || existingBase === baseCode) {
								bid.adjustedAdUnitCode = cand;
								adjustedToBaseMap[cand] = baseCode;
							}
						}
						if (sepIdx >= 0 && bid.adjustedAdUnitCode) {
							// Example "sas_1234_rlv_dup_7" => "1234_rlv_dup_7"
							bid.adjustedAdUnitCode = `${bid.adjustedAdUnitCode}${bid.adUnitCode.slice(sepIdx)}`;
						}
					}
				});
				this.reAdjustDuplicationPaths();
			},

			finalizeBidsBeforeMain() {
				const fns = [(b) => b.finalAdUnitCode(), (b) => b.bidder, (b) => b.sspId];
				const sortedBids = this.allBids().sort((b1, b2) => {
					for (const fn of fns) {
						const v1 = fn(b1) || '';
						const v2 = fn(b2) || '';
						if (v1 !== v2) {
							return v1 < v2 ? -1 : 1;
						}
					}
					return 0;
				});
				let ssMax = 0;
				sortedBids.forEach((bid, idx) => {
					bid.bidIdx = idx;
					ssMax = Math.max(ssMax, bid.ssMs || 0);
				});
				if (ssMax) {
					sortedBids.forEach((bid) => {
						if (bid.ssMs) {
							// Deduct waiting for slower bidders from .responseMs for S2S bid
							bid.responseMs -= ssMax - bid.ssMs;
						}
					});
				}
			},

			getSiteId() {
				const { hbmAuction } = this;
				if (!hbmAuction) {
					return SITE.siteId;
				}
				let maxNum = 0;
				let maxId;
				const count = {};
				hbmAuction.usedUnitDatas.forEach(({ adUnit }) => {
					const { siteId } = adUnit;
					const num = (count[siteId] = (count[siteId] || 0) + 1);
					if (num > maxNum) {
						maxId = siteId;
						maxNum = num;
					}
				});
				return maxId || SITE.siteId;
			},

			sendMainDataInternal() {
				if (this.mainDataSent) {
					return;
				}
				this.initAdUnitCodeMapping();
				this.finalizeBidsBeforeMain();
				this.serverBids = this.serverBidArray();
				const obj = {
					cmd: 'main',
					systemId: SITE.systemId,
					siteId: this.getSiteId(),
					publisherId: SITE.publisherId,
					timestamp: this.timestamp,
					siteLocalId: '0x0', // keep for legacy reasons
					id: this.id,
					bids: this.serverBids,
					customParams: this.getCustomParams(),
				};
				this.sendCommand(obj);
				this.mainDataSent = true;
				this.mainSendPending = false;
				log(`MAIN Analytics request triggered, bids: ${obj.bids.length}`);
			},

			sendMainData() {
				if (this.mainSendPending || this.mainDataSent) {
					return;
				}
				this.mainSendPending = true;
				setTimeout(() => {
					const { waitInit = (cb) => cb() } = Auction.globalSettings;
					let didCall;
					waitInit(() => {
						if (!didCall) {
							didCall = true;
							this.sendMainDataInternal();
						}
					});
				}, mainSendDelay);
			},

			serverBidArray() {
				return this.allBids().map((bid) => bid.toServerObject());
			},

			sendCommand(param) {
				const that = this;
				function runNext() {
					if (!that.pendingCommands.length) {
						that.runningCommand = false;
						return;
					}
					const cmd = that.pendingCommands.shift();
					that.runningCommand = true;
					let called;
					function runNextOnce() {
						if (!called) {
							called = true;
							runNext();
						}
					}
					let cmdStr = JSON.stringify(cmd);
					['%', '&'].forEach((special) => {
						// Use instead of more space-consuming encodeURIComponent
						if (cmdStr.indexOf(special) >= 0) {
							cmdStr = cmdStr.replace(new RegExp(special, 'g'), encodeURIComponent(special));
						}
					});
					if (that.blockCalls) {
						setTimeout(runNextOnce);
					} else if (cmdStr.length > MAX_HBA_GET_PARAM_CHARS) {
						const xhr = new XMLHttpRequest();
						xhr.onload = runNextOnce;
						xhr.onerror = runNextOnce;
						xhr.open('POST', `${analyticsURL}/analytics`);
						xhr.setRequestHeader('Content-Type', 'application/json');
						xhr.send(JSON.stringify(cmd));
					} else {
						const scriptElm = document.createElement('script');
						scriptElm.type = 'text/javascript';
						scriptElm.async = 'async';
						scriptElm.onload = runNextOnce;
						scriptElm.onerror = runNextOnce;
						scriptElm.src = `${analyticsURL}/analytics?param=${cmdStr}`;
						document.head.appendChild(scriptElm);
					}
				}
				this.pendingCommands.push(param);
				if (!this.runningCommand) {
					runNext();
				}
			},
		},
	});

	FIELDS.initAnalytics = (globalSettings, globalOverrideSettings) => {
		if (!FIELDS.analyticsURL) {
			console.error('Relevant Analytics URL is empty');
			return;
		}

		analyticsURL = Utils.makeUrl(FIELDS.analyticsURL);

		const { pbjsName } = FIELDS;
		window[pbjsName] = window[pbjsName] || {};
		const pbjs = window[pbjsName];
		pbjs.que = pbjs.que || [];
		pbjs.que.push(() => {
			Auction.init(pbjs, globalSettings, globalOverrideSettings);
		});
		// eslint-disable-next-line no-undef
		relevantDigital.Auction = Auction;
	};
	window.relevantDigital = window.relevantDigital || {};
	// eslint-disable-next-line no-undef
	relevantDigital.cmd = relevantDigital.cmd || [];
	try {
		// eslint-disable-next-line no-undef
		relevantDigital.platformData = bowser.parse(window.navigator.userAgent);
	} catch (e) { /* ignore.. */ }

	PrebidRequester.init({ FIELDS, SITE, log });

	if (FIELDS.globalJs) {
		// eslint-disable-next-line no-eval
		eval(FIELDS.globalJs);
	}
	if (FIELDS.publisherJs) {
		// eslint-disable-next-line no-eval
		eval(FIELDS.publisherJs);
	}
	if (FIELDS.siteJs) {
		// eslint-disable-next-line no-eval
		eval(FIELDS.siteJs);
	}
	if (FIELDS.enableAnalytics) {
		FIELDS.initAnalytics();
	}
	Utils.processQueue(relevantDigital, 'cmd');
};
