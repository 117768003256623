const { NATIVE_IMAGE_FIELDS } = require('relevant-shared/prebid/native');
const { PREBID_CONFIGURATION_NAME, DEF_FLOOR } = require('./sharedConstants');
const { generateAppnexusOutstreamRendererSettings } = require('./videoUtils');
const AdserverTypes = require('./adserverTypes');
const AdUnit = require('./adUnit');
const Utils = require('./utils');
const BidderHandler = require('./bidderHandler');
const {
	WAITING,
	RUNNING,
	AD_REQUESTING,
	DONE,
} = require('./constants');
const BidUtils = require('./bidUtils');
const VideoSlot = require('./videoSlot');

const { Transparency, AmazonInterface } = window.relevantDigital.exports;

const CLEANUP_PB_FIELDS = ['__slot'];

const getOptiFreshnessDesc = ({ optAge } = {}) => {
	if (!optAge) {
		return optAge === 0 ? 'Latest' : 'Not loaded';
	}
	const opts = [
		['Minute', 60 * 1000, [5, 15, 30]],
		['Hour', 60 * 60 * 1000, [1, 6, 12]],
		['Day', 24 * 60 * 60 * 1000, [1, 7, 14]],
	];
	for (const [name, multi, arr] of opts) {
		for (const num of arr) {
			if (optAge < multi * num) {
				return `< ${num} ${name}${num === 1 ? '' : 's'}`;
			}
		}
	}
	return 'Outdated';
};

class RequestAuction {
	constructor(pbRequester, pbConfig, settings) {
		const defaults = {
			divAttribute: 'data-ad-unit-id',
		};
		Utils.assign(this, defaults, settings, pbConfig, {
			settings,
			pbRequester,
			pbConfig,
			state: WAITING,
			pbjs: pbRequester.pbjs,
			auctionId: Utils.generateUUID(),
			events: {
				hbaAuctionCreated: Utils.onceEvent(),
			},
			adsById: {},
			usedCodes: {},
			sysParams: {},
		});
		pbRequester.cbSet.apply(this, 'auction');
		this.adservers = this.allAdsIds.map((adsId) => {
			const adsSettings = pbRequester.globalAdserverSettings[adsId];
			const AdserverType = AdserverTypes[adsSettings.type];
			const adserver = new AdserverType(this, adsSettings);
			this.adsById[adsId] = adserver;
			return adserver;
		});
		const { getViewportFn } = this;
		this.videoSlots = pbRequester.videoSlots;
		this.viewport = (getViewportFn || BidUtils.getViewPort)();
		this.adUnits = this.adUnits.map((adUnitJson) => new AdUnit({
			adUnitJson,
			auction: this,
			adserver: this.adsById[adUnitJson.adserverId],
		}));
		if (this.hasOptimization) {
			this.optiStats = pbRequester.optimization.optimize(this);
		}
		this.adUnits.forEach((adUnit) => {
			adUnit.initFloor();
		});
		this.setDefFloorsIfNeeded();
		this.transparency = Transparency && new Transparency({ auction: this });
	}

	get adserver() {
		console.warn('Don\'t use .adserver');
		return this.adservers[0];
	}

	get globalAdserverSettings() {
		return this.adserver;
	}

	// TODO (or maybe not..) => optimization isn't done for thes ad units
	addUnitFromTemplate({ adUnitJson }, adUnitPath, adserver) {
		const adUnit = new AdUnit({ adUnitJson, auction: this, adserver });
		if (!adserver.updateAdUnitPath(adUnit, adUnitPath)) {
			return null;
		}
		this.adUnits.push(adUnit);
		adUnit.initFloor();
		this.setDefFloorsIfNeeded();
		return adUnit;
	}

	getHbaSystemParams() {
		const {
			configId, shouldOptimize, hasOptimization, data, optiStats, pbRequester, customParams, sysParams,
		} = this;
		this.setAdsFloors(); // Must be done here to send possible ads-floor-currency-multiplier custom dimensions
		const res = {
			[PREBID_CONFIGURATION_NAME]: configId,
			...customParams,
			...sysParams,
		};
		if (hasOptimization) {
			res.Optimization = shouldOptimize ? 'Enabled' : 'Disabled';
			if (data.rlvOptDebugDims) {
				if (shouldOptimize) {
					res['Optimization freshness'] = getOptiFreshnessDesc(optiStats);
				}
				pbRequester.optimization.allParams.forEach(({ name, rand }) => {
					let group = 'Not optimized';
					if (shouldOptimize) {
						group = rand * 100 < 100 - data.rlvBenchPerc ? 'Optimal' : 'Test';
					}
					res[`Optimization group [${name}]`] = group;
				});
			}
		}
		return res;
	}

	onHbaAuctionCreated(hbaAuction) {
		this.hbaAuction = hbaAuction;
		this.events.hbaAuctionCreated.trigger();
	}

	setupManagedAdservers(setupSettings) {
		const statePerDiv = new Map();
		this.adservers.forEach((adserver) => {
			if (!adserver.isInstreamOnly()) {
				adserver.setup(setupSettings, statePerDiv);
			}
		});
		statePerDiv.forEach((v) => {
			if (v !== true) {
				console.error(v);
			}
		});
	}

	init(settings) {
		const { doneCb } = settings;
		const setupSettings = {
			auction: this,
			divAttribute: this.divAttribute,
		};
		if (this.onBeforeAuctionSetup) {
			this.onBeforeAuctionSetup(setupSettings);
		}
		const afterInit = () => {
			if (this.manageAdserver) {
				this.setupManagedAdservers(setupSettings);
			}
			this.initInternal(settings);
			this.finalAdUnits = this.finalizePbAdUnits(this.usedUnitDatas);
			if (this.onAuctionInitDone) {
				this.onAuctionInitDone({ auction: this });
			}
			doneCb();
		};
		Utils.runFns(this.adservers.map((adserver) => (done) => {
			if (adserver.isInstreamOnly()) {
				done();
			} else {
				adserver.runInit(this, done);
			}
		})).then(afterInit);
	}

	getVastXml(bid) {
		if (bid.vastXml) {
			return bid.vastXml;
		}
		// If no vastXML, create our own containing vastUrl.
		return `
			<VAST version="3.0">
				<Ad>
					<Wrapper>
						<VASTAdTagURI>
							<![CDATA[${bid.vastUrl}]]>
						</VASTAdTagURI>
					</Wrapper>
				</Ad>
			</VAST>`;
	}

	getNonPbjsBidsForHba() {
		return [
			...(this.amazonAuction?.hbaBids ?? []),
			...(this.usedUnitDatas.map((u) => u.adserverBid).filter((u) => u)),
		];
	}

	renderBanner({ bid, divId, adUnit }) {
		delete bid.renderer;
		const elm = document.getElementById(divId);
		if (!elm) {
			throw Error(`Missing divId '${divId}'`);
		}
		adUnit.adserver.prepareIfrDoc(elm, bid, (doc) => {
			if (!doc) {
				console.warn(`Missing document for divId '${divId}'`);
			} else {
				this.pbjsCall('renderAd', doc, bid.adId);
			}
		});
	}

	renderVideo({
		bid, divId, adUnit, pbAdUnit,
	}) {
		const { playerExclusiveOptions, ...otherOptions } = adUnit.videoSettings;
		const [width, height] = pbAdUnit.mediaTypes.video.playerSize;
		const rendererOptions = generateAppnexusOutstreamRendererSettings({
			skip: otherOptions.skip,
			playbackmethod: otherOptions.playbackmethod,
			width,
			height,
			...playerExclusiveOptions,
		});
		const url = 'https://acdn.adnxs.com/video/outstream/ANOutstreamVideo.js';
		this.pbRequester.loadOnce(url, () => {
			window.ANOutstreamVideo.renderAd({
				targetId: divId,
				adResponse: { content: this.getVastXml(bid) },
				rendererOptions: {
					...rendererOptions,
					cbNotification: (eventType, eventName) => {
						const { relevantDigital } = window;
						if (relevantDigital.Auction && eventName === 'impression') {
							relevantDigital.Auction.registerImpression(bid);
						}
					},
				},
			}, (id, eventName) => bid.renderer.handleVideoEvent({ id, eventName }));
		});
	}

	renderNative(params) {
		const { customNativeRender } = this;
		if (!customNativeRender) {
			this.renderBanner(params);
			return;
		}
		const { toLegacyResponse, fireNativeTrackers, getNativeRequest } = this.pbRequester.providedObjects;
		const { bid } = params;
		let { native } = bid;
		const { adId } = bid;
		const ortbRequest = getNativeRequest?.(bid);
		if (native.ortb && ortbRequest && toLegacyResponse) {
			native = toLegacyResponse(native.ortb, ortbRequest);
		}
		customNativeRender({
			...params,
			native,
			trackClick: () => fireNativeTrackers?.({
				action: 'click',
				adId,
			}, bid),
		});
		fireNativeTrackers?.({
			action: 'fireNativeImpressionTrackers',
			adId,
		}, bid);
	}

	initRenderers({ pbAdUnit, adUnit, divId }) {
		const { video, native } = pbAdUnit.mediaTypes;
		const newRenderer = () => ({
			url: 'data:text/javascript,', // no-op (needed)
			render: (bid) => {
				const params = {
					bid, divId, adUnit, pbAdUnit,
				};
				if (bid.mediaType === 'video') {
					this.renderVideo(params);
				} else if (bid.mediaType === 'native') {
					this.renderNative(params);
				} else {
					this.renderBanner(params);
				}
			},
		});
		if (native && this.customNativeRender) {
			native.renderer = newRenderer();
		}
		if (video && video.context !== 'instream') {
			video.renderer = newRenderer();
		}
	}

	setUnitDatas(unitDatas) {
		this.usedUnitDatas = unitDatas;
		this.usedPbAdUnits = unitDatas.map((d) => d.pbAdUnit);
		AmazonInterface?.initAmazonAuctionFor(this);
	}

	onBeforeRequestBids(adUnits) {
		const bidsPerBidder = {};
		adUnits.forEach((unit) => {
			(unit.bids || []).forEach((bid) => {
				if (unit.__resetGetFloors) {
					delete bid.getFloor;
				}
				const { bidder } = bid;
				bidsPerBidder[bidder] = bidsPerBidder[bidder] || [];
				bidsPerBidder[bidder].push(bid);
			});
			delete unit.__resetGetFloors;
		});
		Utils.values(bidsPerBidder).forEach((bids) => BidderHandler.of(bids[0]).finalizeBids?.(bids));
	}

	initInternal() {
		const unitDatas = [];
		const ignoreSlots = new Set();
		this.adservers.forEach((adserver, idx) => {
			const {
				unitDatas: datas,
				unknownSlotsToLoad: unknowns,
			} = adserver.getAdUnitInstances(this, ignoreSlots);
			unitDatas.push(...datas);
			adserver.unknownSlotsToLoad = unknowns;
			if (idx < this.adservers.length - 1) { // Don't do this if it isn't necessary
				[...datas.map((u) => u.slot), ...unknowns].forEach((slot) => ignoreSlots.add(slot));
			}
		});
		this.setUnitDatas(unitDatas);
	}

	removeUnitData(unitData) {
		if (!this.usedUnitDatas) {
			return;
		}
		const unitDatas = this.usedUnitDatas.filter((u) => u !== unitData);
		if (unitDatas.length !== this.usedUnitDatas.length) {
			this.unitDataRemoved = true;
			this.setUnitDatas(unitDatas);
		}
	}

	unitDatasByAds(adserver, includeInstream) {
		return (this.usedUnitDatas || []).filter((u) => (
			u.adserver === adserver && (includeInstream || !(u.slot instanceof VideoSlot))
		));
	}

	onPrebidBidResponses(responses) {
		for (const key in responses || {}) {
			(responses[key]?.bids || []).forEach((bid) => {
				const { native } = bid;
				if (native) {
					const fix = (obj, member) => {
						if (obj && Utils.isString(obj[member]) && obj[member].indexOf('http:') === 0) {
							obj[member] = obj[member].replace('http:', 'https:');
						}
					};
					NATIVE_IMAGE_FIELDS.forEach((fld) => {
						const targKey = `hb_native_${fld}`;
						const str = (bid.adserverTargeting || {})[targKey];
						if (Utils.isString(str) && str.indexOf('http:') === 0) {
							bid.adserverTargeting[targKey] = str.replace('http:', 'https:');
						}
						fix(native, fld);
						fix(native[fld], 'url');
					});
					native.ortb?.assets?.forEach?.((obj) => {
						fix(obj.img, 'url');
						fix(obj.video, 'url');
					});
				}
				this.transparency?.processBid(bid);
			});
		}
	}

	pbjsCall(fnName, ...args) {
		const fn = this.pbjsCalls?.[fnName];
		return fn ? fn.call({ auction: this.auction }, ...args) : this.pbjs[fnName]?.(...args);
	}

	getNonInstreamOnlyAdservers() {
		return this.adservers.filter((ads) => !ads.isInstreamOnly());
	}

	setAdsFloors() {
		if (this.adsFloorsSet) {
			return;
		}
		this.adsFloorsSet = true;
		const pbjsConfig = this.pbjs?.getConfig();
		this.usedUnitDatas.forEach(({ adUnit }) => {
			adUnit.setAdsFloor(pbjsConfig || {});
		});
	}

	run({ isFirstCall }) {
		const { pbjs, amazonAuction, finalAdUnits } = this;
		this.state = RUNNING;
		const sendAdserverRequest = (isTimeout) => {
			if (this.state >= AD_REQUESTING) {
				return;
			}
			this.state = AD_REQUESTING;
			if (this.hbaAuction) {
				Utils.withCatch(() => this.hbaAuction.onHbmAdserverRequestSent({ isTimeout }));
			}
			const cbParams = { auction: this, isTimeout };
			if (this.onBeforeAdRequest) {
				this.onBeforeAdRequest(cbParams);
			}
			const onRequestSent = () => {
				this.state = DONE;
				if (this.onAuctionDone) {
					this.onAuctionDone(cbParams);
				}
				this.pbRequester.onAuctionDone(this);
			};
			this.setAdsFloors();
			const adservers = this.getNonInstreamOnlyAdservers();
			Utils.runFns(adservers.map((adserver) => (done) => {
				adserver.sendAdserverRequest({
					adUnits: this.usedPbAdUnits,
					isFirstCall,
					requestAuction: this,
					onRequestSent: done,
					unknownSlotsToLoad: adserver.unknownSlotsToLoad,
				});
				const { delayedSendAdserver } = adserver.getAdserverProps();
				if (!delayedSendAdserver) {
					done();
				}
			})).then(onRequestSent);
		};
		const hasPbjs = !!Utils.find(finalAdUnits, (u) => u.bids.length);
		let numSourcesLeft = (hasPbjs ? 1 : 0) + (amazonAuction ? 1 : 0);
		const checkReady = () => !numSourcesLeft && sendAdserverRequest();
		const onSourceDone = () => {
			numSourcesLeft -= 1;
			checkReady();
		};
		this.transparency?.initialize();
		const startPbjs = () => pbjs.que.push(() => {
			this.pbRequester.initializePrebidConfig(this);
			this.pbjsCall('removeAdUnit');
			this.pbjsCall('addAdUnits', finalAdUnits);
			this.pbjsCall('requestBids', {
				bidsBackHandler: (responses) => {
					this.onPrebidBidResponses(responses);
					onSourceDone();
				},
				auctionId: this.auctionId,
			});
		});
		if (hasPbjs) {
			startPbjs();
		}
		if (amazonAuction) {
			amazonAuction.run(onSourceDone);
		}
		if (!hasPbjs && this.getNonPbjsBidsForHba().length) {
			// "Force start" HBA as there won't be any event from Prebid
			window.relevantDigital.Auction.auctionInit(this);
		}
		checkReady();
		setTimeout(() => sendAdserverRequest(true), this.pbRequester.pbjsFailsafeTimeout);
	}

	finalizePbAdUnits(adUnitInstances) {
		const finalUnits = [];
		const { s2sAliases } = this.pbRequester;
		const willUsePbs = (bid) => !!s2sAliases[bid.bidder];

		// Only used with mixed ad units
		const splitBids = (pbAdUnit) => {
			const byType = {
				mixed: [], banner: [], video: [], native: [],
			};
			const toSingleFormatBid = (bid, type) => {
				const params = { ...bid.params };
				if (type !== 'video') {
					delete params.video;
				}
				return {
					...bid,
					params,
				};
			};
			pbAdUnit.bids.forEach((bid) => {
				const { __mtl: mtl } = bid.params;
				const bidHandler = BidderHandler.of(bid);
				if (mtl && mtl !== 'mixed') {
					byType[mtl].push(toSingleFormatBid(bid, mtl));
				} else if (bidHandler.noMultiMediaTypeSupport
					|| (willUsePbs(bid) && bidHandler.noMultiMediaTypeSupportS2s)) {
					['banner', 'video', 'native'].forEach((fmt) => {
						if (pbAdUnit.mediaTypes[fmt]) {
							byType[fmt].push(toSingleFormatBid(bid, fmt));
						}
					});
				} else {
					byType.mixed.push(bid);
				}
			});
			return byType;
		};

		// Possibly split up in client-side version + server-side version(s)
		const envPrepare = ({ adUnit, candidate }) => {
			const byS2s = {};
			if (!adUnit.needFinalizeForS2s(candidate)) {
				return [candidate];
			}
			candidate.bids.forEach((bid) => {
				const s2s = willUsePbs(bid);
				(byS2s[s2s] = byS2s[s2s] || []).push(bid);
			});
			if (!byS2s.true) {
				return [candidate];
			}
			const s2sVersion = adUnit.finalizeForS2s(candidate);
			if (!byS2s.false) {
				return [s2sVersion];
			}
			return [
				{ ...candidate, bids: byS2s.false },
				{ ...s2sVersion, bids: byS2s.true },
			];
		};

		const splitBySameBidder = (adUnit) => {
			const arr = [];
			const { splitClientSameBidder: clientSplit } = this;
			adUnit.bids.forEach((bid) => {
				for (const obj of arr) {
					const alwaysAllow = !clientSplit && !willUsePbs(bid);
					if (alwaysAllow || BidderHandler.of(bid).tryUseWithoutSplit(bid, obj)) {
						obj.newUnit.bids.push(bid);
						return;
					}
				}
				const newObj = {
					seenBidders: {},
					newUnit: { ...adUnit, bids: [] },
				};
				BidderHandler.of(bid).tryUseWithoutSplit(bid, newObj);
				newObj.newUnit.bids.push(bid);
				arr.push(newObj);
			});
			return arr.map((o) => o.newUnit);
		};

		const addFinalUnit = (unitData, extra) => {
			const { pbAdUnit, adUnit, adserver } = unitData;
			const candidate = { ...pbAdUnit, ...extra };

			candidate.bids = candidate.bids.map((bid) => {
				let res = bid;
				if (bid.params.__mtl) {
					const newParams = { ...bid.params };
					delete newParams.__mtl;
					res = { ...bid, params: newParams };
				}
				BidderHandler.of(res).addRuntimeBidParams(res, unitData);
				return res;
			});
			let prepared = envPrepare({ adUnit, candidate });
			prepared = [].concat(...prepared.map(splitBySameBidder));
			prepared.forEach((newUnit) => {
				const divId = adserver.getAdDivId(newUnit);
				this.initRenderers({ pbAdUnit: newUnit, adUnit, divId });
				CLEANUP_PB_FIELDS.forEach((fld) => {
					delete newUnit[fld];
				});
				finalUnits.push(newUnit);
				unitData.finalPbAdUnits.push(newUnit);
			});
		};

		adUnitInstances.forEach((adUnitInstance) => {
			const { pbAdUnit } = adUnitInstance;
			if (['banner', 'video', 'native'].filter((fmt) => pbAdUnit.mediaTypes[fmt]).length > 1) {
				// Some bidders does not support multi format adunits (smart, rubicon)
				// For those bidders, we do as described in this article, i.e add another adunit for the same placement
				// https://help.smartadserver.com/s/article/Multi-format-ad-units-not-supported-by-our-prebid-adapter
				const byType = splitBids(pbAdUnit);
				['mixed', 'banner', 'video', 'native'].forEach((type) => {
					const bids = byType[type];
					if (bids.length) {
						addFinalUnit(adUnitInstance, {
							bids,
							...(type !== 'mixed' && {
								mediaTypes: { [type]: pbAdUnit.mediaTypes[type] },
							}),
						});
					}
				});
			} else {
				addFinalUnit(adUnitInstance);
			}
		});
		return finalUnits;
	}

	onExternalSetBidFloor() {
		if (!this.defFloorsSet) {
			this.setDefFloorsIfNeeded();
		}
	}

	setDefFloorsIfNeeded() {
		const firstWithFloor = Utils.find(this.adUnits, (u) => u.pbAdUnit.floors);
		if (!firstWithFloor) {
			return;
		}
		const { currency } = firstWithFloor.pbAdUnit.floors;
		// Workaround to avoid incorrectly applying floors to ad units that shouldn't have any.
		// Problem is that this breaks 'allowZeroCpmBids' settings as bids that are 0 will still not be allowed.
		// TODO: Find a workaround in Prebid
		this.adUnits.forEach(({ pbAdUnit }) => {
			if (pbAdUnit.floors) {
				return;
			}
			pbAdUnit.floors = {
				default: DEF_FLOOR,
				schema: { fields: ['mediaType'] },
				values: { '*': DEF_FLOOR },
				...(currency && { currency }),
			};
			pbAdUnit.__resetGetFloors = true;
		});
		this.defFloorsSet = true;
	}
}

module.exports = RequestAuction;
