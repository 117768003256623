const { NATIVE_FIELDS, NATIVE_IMAGE_FIELDS, TITLE_DEF_LEN } = require('relevant-shared/prebid/native');
const {
	DEF_FLOOR, ADS_OPTI_FLOOR, ADS_NO_OPTI_FLOOR, HIDDEN_PARAM_PREFIX, ADS_FLOOR_MULTI_PREFIX,
} = require('./sharedConstants');
const Utils = require('./utils');
const BidUtils = require('./bidUtils');
const BidderHandler = require('./bidderHandler');

const { AmazonInterface } = window.relevantDigital.exports;

/**
 * This class represents a placement in the prebid configuration. Notice that the same placement can be used
 * any number of times on the page. Therefore one AdUnit might generate multiple entries in pbjs.adUnits.
 * For this purpose we create one AdUnitInstance object for every element in pbjs.adUnits. Example:
 *
 * <div data-ad-unit-id="/12345/top-banner"><div>
 * <div data-ad-unit-id="/12345/top-banner"><div>
 *
 * In this case there will be one AdUnit object in the auction but two AdUnitInstance objects,
 * both referring to the same AdUnit.
 */
class AdUnit {
	constructor({ adUnitJson, auction, adserver }) {
		const { dimensionValidFn, viewport } = auction;
		Utils.assign(this, {
			s2sNativeImageAddFlds: [], // mediaType.native fields that needs adjustments for PBS
			fixedSlots: [],
			auction,
			adserver,
			adUnitJson,
			...Utils.clone(adUnitJson),
		});
		this.amazonBid = AmazonInterface?.getFirstValidAmazonBid(this);
		const placementType = this.getPlacementType();
		const validDimensions = [];
		const invalidDimensions = [];
		const dimValidFn = dimensionValidFn || BidUtils.isDimensionValid;
		placementType.dimensions.forEach((dimension) => {
			const dimParams = {
				dimension,
				adUnit: this,
				placementType,
				auction,
				viewport,
			};
			(dimValidFn(dimParams) ? validDimensions : invalidDimensions).push(dimension);
		});
		const sizes = validDimensions.map(({ width, height }) => [width, height]);
		const {
			videoSettings, nativeSettings, isInstream, formats,
		} = this;
		const mediaTypes = {};
		if (formats.banner) {
			mediaTypes.banner = { sizes };
		}
		if (formats.video) {
			const {
				playerExclusiveOptions,
				adserverTargetingOptions,
				mediaType,
				...otherOptions
			} = videoSettings;
			mediaTypes.video = {
				...otherOptions,
				context: isInstream ? 'instream' : 'outstream',
			};
		}
		if (nativeSettings) {
			mediaTypes.native = nativeSettings;
			NATIVE_FIELDS.forEach((fld) => {
				let setting = nativeSettings[fld];
				if (setting?.disabled) {
					delete nativeSettings[fld];
					return;
				}
				if (!setting) {
					setting = { required: false };
					nativeSettings[fld] = setting;
				}
				if (NATIVE_IMAGE_FIELDS.indexOf(fld) >= 0) {
					if (!setting.sizes && !setting.aspect_ratios) {
						// We need to set aspect_ratios for PBS-adapter, on the other hand these
						// values seems to break Xandr-calls client-side, so they should be different client/s2s
						this.s2sNativeImageAddFlds.push(fld);
					}
				} else if (fld === 'title' && !setting.len) {
					setting.len = TITLE_DEF_LEN; // Required by PBS-adapter
				}
			});
		}
		this.pbAdUnit = {
			mediaTypes,
			sizes,
			bids: BidUtils.filterBids({ bids: this.bids, validDimensions, invalidDimensions }),
		};
		if (adserver.adUnitInit) {
			adserver.adUnitInit({ adUnit: this });
		}
	}

	initFloor() {
		if (!this.floorOptimized && !this.data.rlvFloorOptOnly) {
			this.setBidFloor(this.data.rlvFloor, this.data.rlvFloorCur, { internalCall: true });
		}
	}

	// We need to split into separate client/s2s ad unit units either if we need to add aspect_ratio for images
	needFinalizeForS2s(pbAdUnit) {
		return pbAdUnit.mediaTypes.native && this.s2sNativeImageAddFlds.length;
	}

	// Add mediaTypes.native.[image-type].aspect_ratio
	finalizeForS2s(pbAdUnit) {
		const { s2sNativeImageAddFlds: flds } = this;
		const res = {
			...pbAdUnit,
			mediaTypes: {
				...pbAdUnit.mediaTypes,
				native: { ...pbAdUnit.mediaTypes.native },
			},
		};
		flds.forEach((fld) => {
			res.mediaTypes.native[fld] = {
				...res.mediaTypes.native[fld],
				aspect_ratios: [{
					min_width: 1,
					min_height: 1,
				}],
			};
		});
		return res;
	}

	getHbaFloor() { // return floor-value we should log in HBA-event
		const { floorSet } = this;
		if (typeof floorSet === 'number') {
			return floorSet;
		}
		return this.canAdsOptimize() ? ADS_NO_OPTI_FLOOR : null;
	}

	canAdsOptimize() {
		return (this.data.rlvAdsOptiPerc || 0) > 0 && this.adserver.canAdsOptimize;
	}

	setBidFloor(floor, cur, settings) {
		const { internalCall, floorType } = settings || {};
		const { data, auction, pbAdUnit, adserver } = this;
		const { rlvFloorType, rlvAdsOptiPerc } = data;
		if (!internalCall && (this.floorOptimized || this.floorSet === ADS_OPTI_FLOOR)) {
			return; // If optimization is enabled, ignore this (else optimization might go crazy)
		}

		const floorTypeSet = floorType || rlvFloorType || 'hbOnly';
		if (internalCall && this.canAdsOptimize()) { // Adserver optimize floor
			let adsOpti = true;
			if (rlvAdsOptiPerc < 100) {
				const rnd = auction.pbRequester.getTestRand('adsOptiRand', adserver.floorInfo?.useLocalStorage);
				adsOpti = rnd < rlvAdsOptiPerc / 100;
			}
			if (adsOpti) {
				Object.assign(this, { floorSet: ADS_OPTI_FLOOR, floorTypeSet: 'adsOnly' });
				return;
			}
		}
		if (!floor) {
			return; // Currently floor == 0 means "don't set floor".
		}

		Object.assign(this, { floorSet: floor, floorTypeSet });

		// We can only allow one floor-currency per auction
		const currency = 'floorCurSet' in auction ? auction.floorCurSet : cur;
		auction.floorCurSet = currency;

		if (floorTypeSet === 'adsOnly') {
			return;
		}
		const pbFloor = floor === 0 ? DEF_FLOOR : floor;
		let floors = typeof floor !== 'number' ? floor : {
			default: pbFloor,
			schema: { fields: ['mediaType'] },
			values: { '*': pbFloor },
			...(currency && { currency }),
		};
		pbAdUnit.floors = floors;
		if (!floors) {
			floors = { default: null };
		}
		if ('default' in floors) {
			pbAdUnit.bids.forEach((bid) => {
				BidderHandler.of(bid).setFloor({
					params: bid.params,
					bidFloor: floors.default,
					...(floors.currency ? { bidFloorCurrency: floors.currency } : null),
				});
			});
		}
		if (!internalCall) {
			auction.onExternalSetBidFloor();
		}
	}

	setAdsFloor({ priceGranularity, customPriceBucket }) {
		const {
			floorSet, floorTypeSet, adserver, auction,
		} = this;
		const { floorInfo } = adserver;
		const { pbRequester, floorCurSet, sysParams } = auction;
		if (typeof floorSet !== 'number' || floorTypeSet === 'hbOnly') {
			return;
		}
		this.adsFloor = floorSet;
		const adsCurrency = floorInfo?.currency
			|| pbRequester.prebidConfig.currency?.adServerCurrency
			|| pbRequester.adServerCurrency;
		if (floorCurSet) {
			const multi = auction.pbRequester.currencyConvert(1, floorCurSet, adsCurrency);
			if (multi !== 1) {
				sysParams[`${HIDDEN_PARAM_PREFIX}${ADS_FLOOR_MULTI_PREFIX}${adserver.id}`] = multi;
			}
			this.adsFloor *= multi;
		}

		/** Reason for adjusting adserver-floor-prices down to Prebid price buckets is that prebid-bids might tend to
		 * bid exactly at the floor. If the cpm is then adjusted down slightly, then the prebid line-item will just
		 * miss the floor. */
		if (!priceGranularity || !adserver.bucketAdjustFloors || floorTypeSet === 'adsOnly') {
			return;
		}
		const types = {
			low: [[5, 0.5]],
			medium: [[20, 0.1]],
			high: [[20, 0.01]],
			dense: [[3, 0.01], [8, 0.05], [20, 0.5]],
			auto: [[5, 0.05], [10, 0.1], [20, 0.5]],
		};
		let ranges = types[priceGranularity];
		if (priceGranularity === 'custom') {
			ranges = customPriceBucket?.buckets?.map?.((b) => [b.max, b.increment]);
		}
		if (!ranges?.length) {
			return;
		}
		const [maxCpm] = ranges[ranges.length - 1];
		if (this.adsFloor > maxCpm) {
			this.adsFloor = maxCpm;
			return;
		}
		for (const [max, inc, prec = 2] of ranges) {
			if (this.adsFloor <= max && inc) {
				const multi = Math.min(1 / inc, 10 ** prec);
				this.adsFloor = Math.floor(this.adsFloor * multi) / multi;
				return;
			}
		}
	}

	getBidFloor() {
		const { default: floor, currency } = this.pbAdUnit.floors || {};
		return typeof floor !== 'number' ? null : { floor, currency: currency || 'USD' };
	}

	getAdUnitPaths() {
		return this.adserver.adUnitPathsFromUnit(this);
	}

	getPrebidSizes(alwaysAsArrayOfArrays) {
		const { mediaTypes, sizes } = this.pbAdUnit;
		let res = (mediaTypes.banner || {}).sizes || (mediaTypes.video || {}).playerSize || sizes || [];
		if (alwaysAsArrayOfArrays && res.length === 2 && !Array.isArray(res[0])) {
			res = [res];
		}
		return res;
	}

	getPrimaryPrebidSize() {
		const sizes = this.getPrebidSizes();
		return Array.isArray(sizes[0]) ? sizes[0] : sizes;
	}

	getPlacementType() {
		return this.auction.placementTypesById[this.placementTypeId];
	}

	assignSlot(slot) {
		this.fixedSlots.push(slot);
	}

	createSlot(params = {}) {
		const fallbackVal = `rlv-rnd-${Math.random()}`;
		const slot = this.adserver.createSlotFromAdUnit({
			adUnit: this,
			path: fallbackVal,
			divId: fallbackVal,
			...params,
		});
		this.assignSlot(slot);
		return slot;
	}
}

module.exports = AdUnit;
