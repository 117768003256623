const BidHandler = require('./bidderHandler');

const BidUtils = {
	getViewPort() {
		return {
			vWidth: window.innerWidth,
			vHeight: window.innerHeight,
		};
	},

	isDimensionValid({ dimension, viewport }) {
		const {
			minWidth, maxWidth, minHeight, maxHeight,
		} = dimension;
		const { vWidth, vHeight } = viewport;
		return (!minWidth || vWidth >= minWidth)
			&& (!maxWidth || vWidth < maxWidth)
			&& (!minHeight || vHeight >= minHeight)
			&& (!maxHeight || vHeight < maxHeight);
	},

	filterBids(params) {
		const { bids, validDimensions } = params;
		if (!validDimensions.length) {
			return [];
		}
		return bids.filter((bid) => !BidHandler.of(bid).isInvalid(bid, params));
	},
};

module.exports = BidUtils;
